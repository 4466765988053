<template>
  <!-- Logo -->
  <div class="navbar-brand mr-md-5">
    <router-link to="/" style="text-decoration: none">
      <div class="tenant-logo" v-bind:class="{ hidetenant: !siteLogo }">
        <div>
          <img v-bind:src="siteLogo" />
        </div>
        <span v-if="tenantType" class="tenant-type">{{ tenantType }}</span>
      </div>
    </router-link>
  </div>
  <!-- ./Logo -->
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Logo",
  computed: {
    ...mapState(["session"]),
    siteLogo() {
      return this.session.config["site.logo"] || null;
    },
    tenantType() {
      var hostname = window.location.hostname;
      if (hostname.includes("localhost")) {
        return "Local";
      } else if (hostname.includes("roassist")) {
        return "Demo";
      } else {
        return this.session.config["site.title"] || null;
      }
    },
  },
};
</script>

<style lang="scss">
.navbar {
  padding-left: 15px !important;
}
.navbar-brand {
  display: flex;
  padding: 0;
}
.tenant-logo {
  background-color: #808CCF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 48px;
  margin-top: 8px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  max-width: 200px;
  max-height: 50px;
  overflow: hidden;
}
.tenant-logo img {
  width: 100%;
}
.hidetenant {
  visibility: hidden;
}
.tenant-type {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-left: 12px;
  margin-right: 8px;
}
@media only screen and (max-width: 974px) {
  .tenant-logo {
    visibility: hidden;
  }
}
</style>