<template>
  <!-- Preview Website -->
  <li class="nav-item nav-top">
    <a
      v-bind:href="siteUrl"
      v-bind:target="getTarget"
      class="nav-link"
      data-toggle="tooltip"
      data-placement="bottom"
      v-bind:title="$t('previewSite')"
    >
      <i class="fas fa-eye"></i>
      <span class="d-inline d-lg-none ml-2 ml-lg-0">{{
        $t("previewSite")
      }}</span>
    </a>
  </li>
  <!-- ./Preview Website -->
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Preview",
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    siteUrl() {
      return this.config["site.url"] || "settings";
    },
    getTarget() {
      return this.siteUrl !== "settings" ? "_blank" : "_self";
    },
  },
};
</script>
