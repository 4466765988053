<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-indigo">
    <Logo />
    <Toggler />
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="d-inline ml-auto">
        <ul class="navbar-nav">
          <Language />
          <CacheClear />
          <Short />
          <Preview />
          <Notification />
          <Account />
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Logo from "./components/Logo/Index";
import Toggler from "./components/Toggler/Index";
import Short from "./components/Short/Index";
import Preview from "./components/Preview/Index";
import Notification from "./components/Notification/Index";
import Account from "./components/Account/Index";
import Language from "./components/Language/Index";
import CacheClear from "./components/CacheClear/Index.vue";

export default {
  name: "Top",
  components: {
    Logo,
    Toggler,
    Short,
    Preview,
    Notification,
    Account,
    Language,
    CacheClear
  },
};
</script>

<style lang="scss">
.navbar {
  padding-left: 32px;
  padding-right: 23px;
  padding-bottom: 0;
  padding-top: 0;
}
</style>