<template>
  <span v-bind:id="id" v-bind:class="getClass">
    <div class="media align-items-center">
      <Icon v-bind:type="type" />
      <div class="media-body ml-2">
        <div class="row align-items-center">
          <div class="col">
            <h6 v-if="title" class="mb-0 font-14 text-primary">{{ title }}</h6>
          </div>
          <div class="col text-right">
            <span v-if="time" class="text-muted">{{ time }}</span>
          </div>
        </div>
        <span v-if="message" class="text-muted">{{ message }}</span>
      </div>
    </div>
  </span>
</template>

<script>
import Icon from "./components/Icon/Index";

export default {
  name: "Item",
  components: {
    Icon,
  },
  props: {
    id: {
      default: "",
    },
    type: {
      default: "",
    },
    title: {
      default: "",
    },
    time: {
      default: "",
    },
    message: {
      default: "",
    },
    read: {
      default: "",
    },
  },
  computed: {
    getClass() {
      let result = "dropdown-item mb-2 p-2";

      if (!this.read) {
        result += " unread-notif";
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item {
  cursor: pointer;
}
</style>