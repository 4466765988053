<template>
  <!-- Navbar Toggler -->
  <div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <!-- ./Navbar Toggler -->
</template>

<script>
export default {
  name: "Toggler",
};
</script>