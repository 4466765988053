<template>
  <!-- Short Menu -->
  <ul class="navbar-nav mr-2">
    <li class="nav-item nav-top dropdown">
      <span class="nav-link" data-toggle="dropdown" id="shortMenu">
        <i class="fas fa-th"></i>
        <span class="d-inline d-lg-none ml-2 ml-lg-0">{{
          $t("shortMenu")
        }}</span>
      </span>
      <div class="dropdown-menu" aria-labelledby="shortMenu">
        <router-link to="/products" class="dropdown-item">
          <i class="fas fa-boxes"></i>
          <span class="ml-3 nav-text">{{ $t("products") | toUpperCase }}</span>
        </router-link>
        <router-link to="/products/new" class="dropdown-item">
          <i class="fas fa-plus"></i>
          <span class="ml-3 nav-text">{{
            $t("addProduct") | toUpperCase
          }}</span>
        </router-link>
        <div class="dropdown-divider"></div>
        <router-link to="/categories" class="dropdown-item">
          <i class="fas fa-bars"></i>
          <span class="ml-3 nav-text">{{
            $t("categories") | toUpperCase
          }}</span>
        </router-link>
        <router-link to="/categories/new" class="dropdown-item">
          <i class="fas fa-plus"></i>
          <span class="ml-3 nav-text">{{
            $t("addCategory") | toUpperCase
          }}</span>
        </router-link>
      </div>
    </li>
  </ul>
  <!-- ./Short Menu -->
</template>

<script>
export default {
  name: "Short",
  mounted() {
    $(document).ready(function () {
      $(".dropdown-menu a").on("click", function (e) {
        setTimeout(() => {
          $(".dropdown-menu.show").removeClass("show");
        }, 50);
      });
    });
  },
};
</script>
