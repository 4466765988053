<template>
  <div class="col-auto">
    <span v-bind:class="getClass"></span>
  </div>
</template>

<script>
export default {
  name: "Icon",
  computed: {
    getClass() {
      let result = "priority ";

      switch (this.type) {
        case "danger":
        case "warning":
        case "success":
          result += this.type;
          break;
        default:
          result += "default";
          break;
      }

      return result;
    },
  },
  props: {
    type: {
      default: "",
    },
  },
};
</script>