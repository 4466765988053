<template>
  <!-- Language -->
  <li class="lang-button nav-item nav-top dropdown mr-2">
    <a
      class="nav-link dropdown-toggle"
      v-bind:title="$t('language')"
      data-toggle="dropdown"
    >
      <lang-flag v-bind:iso="$i18n.locale" />
    </a>
    <div class="dropdown-menu">
      <a
        v-bind:class="[
          'dropdown-item',
          $i18n.locale == lang.info.iso ? 'active' : '',
        ]"
        href="#"
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
        v-on:click.prevent="onChange(lang.info)"
      >
        <lang-flag v-bind:iso="lang.info.iso" />
        <span>{{ lang.info.name }}</span>
      </a>
    </div>
  </li>
  <!-- ./Language -->
</template>

<script>
import LangFlag from "vue-lang-code-flags";
import { messages } from "@/locales";

export default {
  name: "Language",
  components: { LangFlag },
  data() {
    return { langs: [] };
  },
  methods: {
    onChange(lang) {
      this.$i18n.locale = lang.iso;
      localStorage.setItem("_lang", lang.iso);
      localStorage.setItem("_lang_code", lang.code);
    },
  },
  mounted() {
    this.langs = messages;
  },
};
</script>

<style lang="scss">
.lang-button {
  display: flex;
  justify-content: center;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.dropdown-item span {
  margin-left: 8px;
}
</style>