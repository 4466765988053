<template>
  <!-- Profile -->
  <li class="nav-item nav-top ml-2 dropdown">
    <router-link
      to=""
      class="d-flex align-items-center no-decoration justify-content-center dropdown-toggle p-1 pl-2 pr-2 profile-btn"
      data-toggle="dropdown"
      id="profile-drop"
    >
      <img src="https://picsum.photos/30/30" class="rounded-circle" />
      <span class="ml-2 mr-2">{{ getUserName }}</span>
    </router-link>
    <div
      class="dropdown-menu dropdown-menu-lg-right"
      aria-labelledby="profile-drop"
    >
      <router-link to="/logout" class="dropdown-item">
        <i class="fas fa-sign-out-alt"></i>
        <span class="ml-3">{{$t('logOut')}}</span>
      </router-link>
    </div>
  </li>
  <!-- ./Profile -->
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Account",
  computed: {
    ...mapState(["session"]),
    getUserName() {
      let session = JSON.parse(localStorage.getItem("session"));
      return this.session.userFullName ?? session.userFullName;
    },
  },
};
</script>

<style lang="scss">
.profile-btn,
.profile-btn:hover,
.profile-btn:visited {
  text-decoration: none;
  color: #ffffff;
}
</style>
