<template>
  <li class="nav-item nav-top mr-2">
    <a
      class="nav-link"
      data-toggle="tooltip"
      data-placement="bottom"
      v-tooltip.bottom="'Vitrin Önbelleğini Temizle'"
      v-on:click.prevent="clearCache"
    >
      <i v-bind:class="['fas fa-sync', submit ? 'fa-spin' : '']"></i>
      <span class="d-inline d-lg-none ml-2 ml-lg-0">Vitrin Önbelleğini Temizle</span>
    </a>
  </li>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "CacheClear",
  data() {
    return {
      submit: false,
    };
  },
  methods: {
    ...mapActions("cacheClear", ["showcaseClear"]),
    clearCache() {
      this.submit = true;
      this.showcaseClear({
        onSuccess: () => {
          this.submit = false;
          this.$toast.success("Vitrin önbelleği başarıyla temizlendi.");
        },
        onFinish: () => {
        }
      });
    },
  },
};
</script>