<template>
  <!-- Notifications -->
  <li class="nav-item nav-top ml-2 dropdown">
    <span class="nav-link" data-toggle="dropdown" id="notifications">
      <i class="fas fa-bell"></i>
      <span class="d-inline d-lg-none ml-2 ml-lg-0">{{$t('notifications')}}</span>
    </span>
    <div
      class="
        dropdown-menu dropdown-large dropdown-menu-lg-right
        notification-menu
      "
      aria-labelledby="notifications"
    >
      <h5 class="dropdown-header pl-0 mb-2 text-dark">
        <i class="fas fa-bell"></i> {{$t('notifications')}}
      </h5>
      <div class="dropdown-divider"></div>
      <div class="notification-body text-center">
        <i>{{$t('notificationDoesNotExist')}}</i>
        <!-- <Item
          type="danger"
          title="BAŞLIK 1"
          time="15.15"
          message="1. Mesajımız"
        />
        <Item
          type="success"
          title="BAŞLIK 2"
          time="14.14"
          message="2. Mesajımız"
          read="true"
        />
        <Item
          type="warning"
          title="BAŞLIK 3"
          time="13.13"
          message="3. Mesajımız"
          read="true"
        />
        <Item
          title="BAŞLIK 4"
          time="09.09"
          message="4. Mesajımız"
          read="true"
        /> -->
      </div>
      <!-- <div class="dropdown-divider"></div>
      <div class="text-center">
        <span class="text-link text-primary font-14"
          >Tümünü Okundu Olarak İşaretle</span
        >
      </div> -->
    </div>
  </li>
  <!-- ./Notifications -->
</template>

<script>
import Item from "./components/Item/Index";

export default {
  name: "Notification",
  components: {
    Item,
  },
};
</script>

<style lang="scss">
.dropdown-large {
  padding: 20px;
}

.notification-body {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  overflow-x: hidden;
}

.notification-menu .dropdown-item {
  border-radius: 6px;
}

.notification-menu .dropdown-item:hover {
  background-color: #ddecff;
}

.notification-body {
  font-size: 0.8rem !important;
}

#notifications {
  position: relative;
}

.notif-dot {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 100%;
}

.priority {
  width: 15px;
  height: 15px;
  float: left;
  border-radius: 100%;
}

.priority.danger,
.notif-dot.unread {
  border: 3px solid #ff4757;
}

.priority.success,
.notif-dot.read {
  border: 3px solid #2ed573;
}

.priority.warning {
  border: 3px solid #dcb05f;
}

.priority.default {
  border: 3px solid #7a7a7a;
}

.unread-notif,
.unread-notif:hover {
  background-color: #e6eaef !important;
}

@media all and (min-width: 992px) {
  .dropdown-large {
    min-width: 500px;
  }
}
</style>